<template>
  <div class="page_heading">
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h1>{{ payload.heading }}</h1>
          <a v-if="payload.link" :href="payload.link.url" :target="payload.link.target">{{ payload.link.title }}
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 1.125V2.375H12.2411L5.99112 8.625L6.875 9.50888L13.125 3.25888V6.75H14.375V1.75C14.375 1.40482 14.0952 1.125 13.75 1.125H8.75Z" fill="white"/>
              <path d="M1.25 2.375C0.904822 2.375 0.625 2.65482 0.625 3V14.25C0.625 14.5952 0.904822 14.875 1.25 14.875H12.5C12.8452 14.875 13.125 14.5952 13.125 14.25V8H11.875V13.625H1.875V3.625H8.125V2.375H1.25Z" fill="white"/>
            </svg>
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    export default {
        name: "Header",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
.page_heading {
  padding: 4rem;
  background: $primary;
  color: $white;

  h1 {
    font-family: 'Noto Sans';
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 54px;
  }

  a {
    color: $white;
    font-family: 'Noto Sans';
    font-weight: 400;
    line-height: 20px;
    margin-top: 1.5rem;
    display: block;

    svg {
      margin-left: 8px;
      transform: translateY(-1px);
    }
  }
}
</style>