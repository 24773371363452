<template>
    <div>
        <page-header :payload="{ heading: 'Aktualności' }"></page-header>

        <section class="news">
            <b-container>
                <b-row>
                    <b-col>
                        <div v-if="news" class="row">
                            <b-col cols="12" md="4" lg="3" v-for="n in news" :key="n.id" class="post_container">
                                <b-card :img-src="n['_embedded']['wp:featuredmedia'][0].source_url" :title="n.title.rendered">
                                    <b-card-text v-html="n.excerpt.rendered"></b-card-text>
                                    <a :href="'/news/' + n.slug">Czytaj dalej</a>
                                </b-card>
                            </b-col>
                        </div>
                        <p v-else>Brak aktualności</p>
                    </b-col>
                </b-row>
                <b-row class="row text-center loader-box" v-if="isLoading">
                    <b-col>
                        <span class="loader"></span>
                    </b-col>
                </b-row>
                <b-row class="pagination row" v-if="news">
                    <b-button class="pagination-button" variant="primary" @click="previousPage" :disabled="currentNewsPage <= 1">
                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(180deg)">
                            <path d="M15.2684 5.48817C15.3854 5.3981 15.3854 5.22165 15.2684 5.13158L9.11225 0.392582C8.9643 0.278688 8.75 0.384159 8.75 0.570873V4.18488H0.875C0.667893 4.18488 0.5 4.35277 0.5 4.55988V6.05988C0.5 6.26699 0.667893 6.43488 0.875 6.43488H8.75V10.0489C8.75 10.2356 8.9643 10.3411 9.11225 10.2272L15.2684 5.48817Z" fill="white"/>
                        </svg>
                    </b-button>
                    <input :min="1" :max="totalPages" type="number" v-model.number="desiredPage" @change="goToPage" />
                    <span class="pagination-page">{{ currentNewsPage }} / {{ totalPages }}</span>
                    <b-button class="pagination-button" @click="nextPage" :disabled="currentNewsPage >= totalPages">
                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2684 5.48817C15.3854 5.3981 15.3854 5.22165 15.2684 5.13158L9.11225 0.392582C8.9643 0.278688 8.75 0.384159 8.75 0.570873V4.18488H0.875C0.667893 4.18488 0.5 4.35277 0.5 4.55988V6.05988C0.5 6.26699 0.667893 6.43488 0.875 6.43488H8.75V10.0489C8.75 10.2356 8.9643 10.3411 9.11225 10.2272L15.2684 5.48817Z" fill="white"/>
                        </svg>
                    </b-button>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    import PageHeader from "../components/blocks/Header"
    export default {
        name: "News",
        components: { PageHeader },
        data() {
            return {
                page: 0,
                isActive: false,
                currentNewsPage: 1,
                perPage: 12,
                desiredPage: null,
            }
        },
        computed: {
            currentPage() {
                return this.$store.state.currentPage;
            },
            totalPages() {
                return this.$store.state.totalPages;
            },
            news() {
                return this.$store.getters.currentPosts;
            },
            isLoading() {
                return this.$store.state.isLoading;
            }
        },
        mounted() {
            this.fetchPosts();
        },
        methods: {
            fetchPosts(page = this.currentNewsPage) {
                this.$store.dispatch('getAllPosts', { type: 'posts', page: this.currentNewsPage, perPage: this.$store.state.perPage });
                this.$store.commit('SET_CURRENT_PAGE', page);
            },
            nextPage() {
                this.currentNewsPage += 1;
                this.fetchPosts();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            previousPage() {
                if (this.currentNewsPage > 1) {
                    this.currentNewsPage -= 1;
                    this.fetchPosts();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            },
            goToPage() {
                const page = this.desiredPage;

                if (page < 1 || page > this.totalPages) {
                    return;
                }

                this.currentNewsPage = page;

                this.fetchPosts(this.currentNewsPage);
                this.desiredPage = null;
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped lang="scss">
    .contrast {
      .card {
        background: $black;
        color: $white;
      }
    }

    section.news {
      padding-top: 80px;
      padding-bottom: 80px;

      .card {
        border-radius: 0;

        img {
          border-radius: 0;
        }

        h4 {
          color: $primary;
          font-family: 'Noto Sans';
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 25px;
          margin-bottom: 1.5rem;
        }

        p {
          font-family: 'Noto Sans';
          font-size: 0.9375rem;
          font-weight: 400;
          line-height: 27px;
          margin-bottom: 1.5rem;
        }

        a {
          font-family: 'Noto Sans';
          font-size: 0.9375rem;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0em;
        }

        .card-body {
          padding: 1rem;
        }
      }
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin-top: 40px;
        margin-bottom: 20px;
        margin-right: 0;
    }

    .pagination-button {
        display: flex;
        align-items: center;
    }

    .loader-box {
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination-page {
        font-size: 20px;
    }

    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid $primary;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
